
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";



export default defineComponent({
  name: "client-listing",
   data() {
    return {
      pedidos: [],
      pedido: {
        contatos:[{}],
        enderecos:[{}],
        forma_pagamentos:[{}]
      },
      data_ini: '',
      data_fim: '',
      whatsapp: ''
    }
  },
  mounted() {
	   this.$nextTick(function () {
		 this.getPedidos();
     this.data_ini = this.currentDate();
     this.data_fim = this.currentDate();
	
	})

  },

  watch:{
    data_fim(value, old) {
		 
      if(this.data_ini > value){

         Swal.fire(
            'Data Incorreta!',
            'Data Inicial não pode ser maior que a data Final!.',
            'error'
          )

      }else{

        this.getPedidosByDate(this.data_ini, this.data_fim);


      }


    },

	
  },

 
  methods:{


     currentDate() {
      // const current = new Date();
      // const date = current.getFullYear()+'-'+ ("0" + (current.getMonth() + 1)).slice(-2) +'-'+  ("0" + (current.getDate() + 1)).slice(-2);
      // const dateTime = date;

      const current = new Date();
      //const date = current.getFullYear()+'-'+ ("0" + (current.getMonth() + 1)).slice(-2) +'-'+  ("0" + (current.getDate() + 1)).slice(-2);
      var dateTime = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      
      

      return dateTime;

    },


    format_date(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY') 
          }
      },

      format_time(value){
         if (value) {
           return moment(String(value)).format('hh:mm') 
          }
      },

  novo(){

    this.pedido =  {
        contatos:[{}],
        enderecos:[{}],
        forma_pagamentos:[{}]
      }

  },


  editar(index){

    this.pedido = this.pedidos[index]


  },  

  excluir(id){

    Swal.fire({
      title: 'Excluir?',
      text: "Tem certeza que deseja excluir este registro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir!'
    }).then((result) => {
      if (result.isConfirmed) {


        axios.delete(process.env.VUE_APP_API_URL + '/pedidos/'+id)
       .then(({ data }) => {   
          Swal.fire(
            'Deletado!',
            'Seu registro foi deletado.',
            'success'
          ).then(()=>{
            this.getPedidos();
          })



        })
        
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          console.log(response)
        });


      }

       
      
    })

  },
    
	getPedidos(){

		axios.get(process.env.VUE_APP_API_URL + '/pedidos')
       .then(({ data }) => {   
        this.pedidos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


    },

    preparaWhats(pedido){

      console.log(pedido)

		var link = 'https://api.whatsapp.com/send?phone=556133525102&text='

		var texto = "*Cliente*: "+pedido.cliente.nome+"\n*Endereço*: "+pedido.endereco.endereco+"\n*Bairro*: "+pedido.endereco.bairro+"\n*Telefone*: "+pedido.contato.contato+"\n*Qtd*: "+pedido.qtds+"\n*Valor*: "+pedido.total+"\n*Forma Pagamento*: "+pedido.forma_pagamento.nome+"\n*Observacao*: "+pedido.observacao+"\n*link de acesso*: http://backend.rjcompany.com.br/pedido/"+pedido.id

		texto = window.encodeURIComponent(texto);

		this.whatsapp = link + texto;

   
    window.open(this.whatsapp, '_blank');

	},

  getPedidosByDate(data_ini, data_fim){

   
		axios.get(process.env.VUE_APP_API_URL + '/pedidosPorData',  {
    params: {
          data_ini: data_ini,
          data_fim: data_fim
        }
      })
       .then(({ data }) => {   
        this.pedidos = data
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });


    }




  },
  components: {
    Datatable,
    AddCustomerModal
    
  },
 
  setup() {
   
    const tableHeader = ref([
     
      {
        name: "Data",
        key: "nome",
        sortable: true,
      },
      {
        name: "Hora",
        key: "nome",
        sortable: true,
      },
      {
        name: "Status",
        key: "cpf_cnpj",
        sortable: true,
      },
      {
        name: "Cliente",
        key: "contato",
        sortable: true,
      },
      {
        name: "Contato",
        key: "endereço",
        sortable: true,
      },
       {
        name: "Endereço",
        key: "bairro",
        sortable: true,
      },
      {
        name: "Produto",
        key: "bairro",
        sortable: true,
      },
      {
        name: "Quantidade",
        key: "bairro",
        sortable: true,
      },
      {
        name: "Total",
        key: "bairro",
        sortable: true,
      },
      {
        name: "Opções",
        key: "opcao",
        sortable: false,
        
      }
    ]);

    onMounted( async () => {
      setCurrentPageBreadcrumbs("Lista de Pedidos", ["Pedidos"]);
      
     
    });  

   
    return {
       tableHeader,
      
      
    };
  },
});
